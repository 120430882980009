import React from "react";
import { ANALYTICS_MODULE } from "constants/index";
import Flex from "app/components/Flex";
import { ChevronRight } from "app/components/Icon";
import { H3, P1 } from "app/components/Typography";
import abbreviateCount from "helpers/abbreviateCount";
import { VerticalBar } from "app/components/VerticalBar";
import { UploadUserVideoModal } from "app/components/UploadUserVideoModal";
import { ChallengeSubmissionsList } from "app/components/Challenges";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setWidgetPlaying } from "modules/community";
import challengesDaysLeft from "helpers/challengesDaysLeft";
import LinkButton from "app/components/Button/LinkButton";
import { FlexLink } from "./styles";

const ChallengeInfo = ({ challenge }) => {
  const { id, submissionsCount, isActive } = challenge;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const abbreviatedSubmissionsCount = abbreviateCount(submissionsCount);
  const daysLeft = challengesDaysLeft(challenge);
  const daysLeftText = `day${daysLeft > 1 ? "s" : ""} left!`;

  const onOpenModal = postId => {
    history.push({
      pathname: `/post/${postId}`,
      state: {
        modal: true,
        challengeId: id,
        previousLocation: location,
        fromModule: ANALYTICS_MODULE.community_feed,
      },
    });
    dispatch(setWidgetPlaying());
  };

  const handleOnClick = () => {
    history.push(`/challenges/${id}`);
  };

  const firstSubmissionId = challenge.videos.edges[0]?.node?.id;

  return (
    <Flex
      ml="24px"
      mt={4}
      flexDirection="column"
      width="375px"
      display={{ _: "none", sm: "flex" }}
    >
      <FlexLink mb={3} alignItems="center" onClick={handleOnClick}>
        <H3 mr={1}>Challenge info</H3>
        <ChevronRight height="11px" />
      </FlexLink>
      <Flex mb={3}>
        <P1 color="monochrome.6" fontWeight="500">
          <b>{abbreviatedSubmissionsCount}&nbsp;</b>
          {abbreviatedSubmissionsCount === "1" ? "SUBMISSION" : "SUBMISSIONS"}
          &nbsp;&nbsp;
        </P1>
        {isActive && (
          <>
            <VerticalBar />
            <P1 fontWeight="600" color="gold">
              &nbsp;&nbsp;{`${daysLeft} ${daysLeftText}`}
            </P1>
          </>
        )}
      </Flex>

      {firstSubmissionId && (
        <FlexLink
          width="100%"
          mb={2}
          alignItems="center"
          onClick={() => onOpenModal(firstSubmissionId)}
        >
          <P1 mr={1} fontWeight="bold">
            Submissions
          </P1>
          <ChevronRight height="11px" />
        </FlexLink>
      )}

      <ChallengeSubmissionsList
        challenge={challenge}
        onOpenModal={onOpenModal}
      />

      <Flex mt={3} mr={1} maxWidth="100%">
        <Flex flex={1}>
          <LinkButton
            to={`/class/${challenge.forClass.id}`}
            variant="hollowBlue"
            padding="12px 0"
            width="100%"
            mr={2}
          >
            LEARN THE DANCE
          </LinkButton>
        </Flex>
        <Flex flex={1}>
          <UploadUserVideoModal
            challengeId={id}
            fromModule={ANALYTICS_MODULE.community_feed}
            buttonProps={{
              buttonBg: "primary.0",
              hoverBg: "primary.1",
              padding: "12px 0",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChallengeInfo;
