import styled from "styled-components";
import Flex from "app/components/Flex";

const VerticalBar = styled(Flex)`
  width: 5px;
  height: 0px;
  border: 1px solid #cbcece;
  transform: rotate(90deg);
  align-self: center;
`;

export { VerticalBar };
